import { useQuery } from '@tanstack/react-query';

import apiClient from '../apiClient';
import { CategoryRes } from '@/types/category';

export const useGetAllCategory = ({
  search,
  parentId,
  all = false,
}: {
  search?: string;
  parentId?: string;
  all?: boolean;
}) => {
  return useQuery<CategoryRes>(['GetTags'], async () => {
    return await apiClient
      .get('tags', {
        params: {
          parentTagId: parentId ? parentId : null,
          all,
          search,
        },
      })
      .then((res) => res.data);
  });
};
