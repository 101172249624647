import { ChevronDown, X } from 'lucide-react';
import { useState } from 'react';
import { UseFormRegister } from 'react-hook-form/dist/types';

type category = {
  id: string;
  name: string;
  thumbnailUrl: string;
  createdAt: string;
  updatedAt: string;
  parentTagId?: string | null;
  subCategories?: category[];
};

type MultiSelectProps = {
  options?: category[];
  register: UseFormRegister<any>;
  setSelectedTags: (selected: string[]) => void;
  selectedTags: string[];
  error: any;
};

const MultiSelectWithSubCategory = ({
  error,
  options = [],
  register,
  setSelectedTags,
  selectedTags,
}: MultiSelectProps) => {
  const [showOptions, setShowOptions] = useState(false);
  const [activeMainCategory, setActiveMainCategory] = useState<string | null>(
    null,
  );

  const toggleTagSelection = (tagName: string) => {
    if (selectedTags.includes(tagName)) {
      setSelectedTags(selectedTags.filter((id) => id !== tagName));
      setShowOptions(false);
    } else {
      setSelectedTags([...selectedTags, tagName]);
      setShowOptions(false);
    }
  };

  const renderOptions = () => {
    return options.map((option) => {
      const hasSubCategories =
        option.subCategories && option.subCategories.length > 0;

      return (
        <div key={option.id} className="w-full">
          {/* Main Category */}
          <div
            className={`flex cursor-pointer items-center justify-between border-b bg-white p-3 hover:bg-slate-200 ${
              selectedTags.includes(option.name) ? 'bg-blue-100' : ''
            }`}
            onClick={() => {
              if (!hasSubCategories) {
                toggleTagSelection(option.name);
              } else {
                setActiveMainCategory(
                  activeMainCategory === option.id ? null : option.id,
                );
              }
            }}
          >
            <span>{option.name}</span>
            {hasSubCategories && <ChevronDown />}
          </div>

          {/* Subcategories */}
          {hasSubCategories && activeMainCategory === option.id && (
            <div className="ml-4 border-l">
              {option?.subCategories?.map((subOption) => (
                <div
                  key={subOption.id}
                  className={`cursor-pointer border-b bg-white p-3 hover:bg-slate-200 ${
                    selectedTags.includes(subOption.name) ? 'bg-blue-100' : ''
                  }`}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent main category click
                    toggleTagSelection(subOption.name);
                  }}
                >
                  {subOption.name}
                </div>
              ))}
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <div className="relative flex w-full flex-col">
      {/* Input Box to toggle dropdown */}
      <div
        onClick={() => setShowOptions(!showOptions)}
        className="flex w-full cursor-pointer items-center justify-between rounded-xl border-2 border-blue-500 p-2.5 outline-none"
      >
        <span className="text-gray-600">Select a category...</span>
        <ChevronDown />
      </div>
      {error && <p className="text-red-500">Choose at least one tag!</p>}

      {/* Selected Tags */}
      <div className="mt-2 flex flex-wrap gap-1">
        {selectedTags.map((tagName) => (
          <div
            key={tagName}
            className="flex cursor-pointer gap-2 rounded-lg bg-slate-200 p-2"
            onClick={() => toggleTagSelection(tagName)}
          >
            {tagName} <X className="rounded-full bg-white p-1 text-sm" />
          </div>
        ))}
      </div>

      {/* Dropdown Options */}
      {showOptions && (
        <div className="absolute top-12 z-50 max-h-[300px] w-full overflow-y-scroll rounded-md border bg-slate-300">
          {options.length > 0 ? (
            renderOptions()
          ) : (
            <div className="p-3 text-center text-gray-500">
              No options found
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MultiSelectWithSubCategory;
