import { Button } from '@/components/common/Button';
import InputBox from '@/components/common/form/InputBox';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useUploadVideos } from '@/api/video/useUploadVideos';
import { X } from 'lucide-react';
import { useGetAllCategory } from '@/api/category/useGetAllCategory';
import { useEffect, useState } from 'react';
import MultiSelect from '@/components/common/form/MultiSelect';

import UploadProgress from '@/components/quick-learn/UploadProgress';
import { yupResolver } from '@hookform/resolvers/yup';
import useSetUrlQuery from '@/utils/query';
import { toast } from 'react-toastify';
import { uploadVideoValidationSchema } from '@/validators/video';
import { uploadVideoProps } from '@/types/videos';
import FileInputBox from '@/components/common/form/FileInputBox';
import ThumbnailBox from '@/components/common/form/ThumbnailBox';
import MultiSelectWithSubCategory from '@/components/common/form/MultiSelectWithSubCategory';

const CreateVideo = () => {
  const [loaded, setLoaded] = useState<number>(0);
  const { mutate, isLoading } = useUploadVideos({ setLoaded });
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [duration, setDuration] = useState<any>(45);
  const [isPublic, setIsPublic] = useState<boolean>(true);
  const { getQuery } = useSetUrlQuery();
  const tagName = getQuery('tag') || '';
  const { data: tags } = useGetAllCategory({ search: tagName, all: true });
  // console.log(tags?.data);
  const [video, setVideo] = useState<any>();
  const [parsedTags, setParsedTags] = useState<any[]>([]);
  const [thumbnail, setThumbnail] = useState<any>();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    //@ts-expect-error
  } = useForm<uploadVideoProps>({
    resolver: yupResolver(uploadVideoValidationSchema),
  });

  const parseCategories = (categories: any[]) => {
    const mainCategories = categories.filter(
      (category) => !category.parentTagId,
    );
    const subCategories = categories.filter((category) => category.parentTagId);

    const categoryHierarchy = mainCategories.map((mainCategory) => {
      return {
        ...mainCategory,
        subCategories: subCategories.filter(
          (subCategory) => subCategory.parentTagId === mainCategory.id,
        ),
      };
    });

    return categoryHierarchy;
  };

  useEffect(() => {
    if (tags?.data) {
      setParsedTags(parseCategories(tags.data));
    }
  }, [tags]);

  const onSubmitUpload = (data: any) => {
    let formData = new FormData();
    formData.append('video', video);
    if (data?.description) {
      formData.append('description', data.description);
    }
    formData.append('thumbnail', thumbnail);
    formData.append('isPublic', isPublic.toString());
    formData.append('title', data.title);
    selectedTags?.forEach((tag) => formData.append('tags', tag))
    // formData.append('tags', selectedTags);
    formData.append('duration', duration);

    // console.log(formData);
    // return;

    mutate(formData, {
      onError(err: any) {
        toast.error(err?.message);
      },
      onSuccess() {
        toast.success('upload successfully!');
        reset();
      },
    });
  };

  console.log(parsedTags, "test")
  return (
    <div className="m-5 flex h-screen flex-col items-center justify-start rounded-md bg-white">
      <button
        onClick={() => navigate(-1)}
        className=" absolute right-7 top-7 p-4"
      >
        <X />
      </button>

      <div className="mt-7 w-full px-8 py-4 text-center text-2xl font-semibold">
        Create Video
      </div>
      {/* create form start */}
      <form
        onSubmit={handleSubmit(onSubmitUpload)}
        className="flex w-[460px] flex-col items-center justify-center gap-2 p-8"
      >
        <InputBox
          type="text"
          error={errors.title?.message}
          name="title"
          register={register}
          id="title"
          placeholder="video title"
          defaultValue=""
        />
        {/* <MultiSelect
          options={tags?.data}
          error={selectedTags == ''}
          register={register}
          setSelectedTags={setSelectedTags}
          selectedTags={selectedTags}
        /> */}
        <MultiSelectWithSubCategory
          options={parsedTags}
          error={!selectedTags.length}
          register={register}
          setSelectedTags={setSelectedTags}
          selectedTags={selectedTags}
        />
        <FileInputBox
          setVideo={setVideo}
          setDuration={setDuration}
          id="video"
          name="videos"
          register={register}
          placeholder="video"
          error={!video}
        />
        <ThumbnailBox
          setThumbnail={setThumbnail}
          name="thumbnail"
          error={!thumbnail}
          register={register}
          id="thumbnail"
          placeholder="thumbnail"
        />
        <InputBox
          type="text"
          name="description"
          error={errors.description?.message}
          register={register}
          id="description"
          placeholder="description"
        />
        <div className="flex w-full items-center justify-start gap-3">
          <input
            type="radio"
            name="isPublic"
            checked={!isPublic}
            onChange={() => setIsPublic(false)}
          />{' '}
          Private
          <input
            type="radio"
            name="isPublic"
            checked={isPublic}
            onChange={() => setIsPublic(true)}
          />
          Public
        </div>
        <Button
          type="submit"
          className="mt-5 w-[115px] rounded-xl"
          isLoading={isLoading}
          variant="transparent"
        >
          {isLoading ? 'Uploading' : 'Upload'}
        </Button>
      </form>
      {/* create form end */}
      <UploadProgress loaded={loaded} isLoading={isLoading} />
    </div>
  );
};

export default CreateVideo;
